<template>
  <div>
    <h4 class="mb-2">
      Hero Input Money V2
    </h4>

    <h5 class="mb-2">
      Calculation
    </h5>
    <HeroInputMoneyV2
      id="value-1"
      v-model="value1"
      :minimum-fraction-digits="2"
      :maximum-fraction-digits="2"
      rounding-mode="trunc"
      copiable
    />
    <div class="d-flex justify-content-end gx mb-1">
      <HeroButtonAction
        v-for="op in ['+', '-', '*', '/']"
        :key="op"
        :variant="operator === op ? 'primary' : 'outline-primary'"
        type="button"
        class="font-medium-3 w-auto mt-0"
        @click="operator = op"
      >
        {{ op }}
      </HeroButtonAction>
    </div>
    <HeroInputMoneyV2
      id="value-2"
      v-model="value2"
      :minimum-fraction-digits="2"
      :maximum-fraction-digits="2"
      rounding-mode="trunc"
      copiable
    />

    <div class="d-flex justify-content-between">
      <div class="font-medium-3 font-weight-bold">
        RESULT: {{ formatter(result) }}
      </div>
      <HeroButtonAction
        variant="primary"
        type="button"
        class="w-auto"
        @click="result = calculateFn[operator]()"
      >
        Calculate
      </HeroButtonAction>
    </div>

    <div class="row">
      <div class="col-12 col-md-4">
        <h5 class="mb-2">
          Min-Max
        </h5>

        <HeroInputMoneyV2
          id="value-3"
          v-model="value3"
          :min="0"
          :max="10000.55"
          :loading="loading"
          :minimum-fraction-digits="2"
          :maximum-fraction-digits="2"
          rounding-mode="trunc"
          copiable
          @less-than-min="onLessThanMin"
          @greater-than-max="onGreaterThanMax"
        />
      </div>

      <div class="col-12 col-md-4">
        <h5 class="mb-2">
          Integer Only
        </h5>

        <HeroInputMoneyV2
          id="value-4"
          v-model="value4"
          copiable
        />
      </div>

      <div class="col-12 col-md-4">
        <h5 class="mb-2">
          Without Grouping
        </h5>

        <HeroInputMoneyV2
          id="value-4"
          v-model="value5"
          :use-grouping="false"
          :minimum-fraction-digits="2"
          :maximum-fraction-digits="2"
          rounding-mode="trunc"
          copiable
        />
      </div>
    </div>
  </div>
</template>

<script>
import HeroInputMoneyV2 from '@/views/components/form/inputs/HeroInputMoneyV2.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'

export default {
  components: {
    HeroButtonAction,
    HeroInputMoneyV2,
  },
  data() {
    return {
      operator: '+',
      value1: '0.00',
      value2: '0.00',
      result: '0.00',
      value3: '0.00',
      value4: '0.00',
      value5: '0.00',
      loading: false,
    }
  },
  computed: {
    calculateFn() {
      return {
        '+': () => (+this.value1) + (+this.value2),
        '-': () => (+this.value1) - (+this.value2),
        '*': () => (+this.value1) * (+this.value2),
        '/': () => (+this.value1) / (+this.value2),
      }
    },
  },
  // mounted() {
  //   setTimeout(() => {
  //     this.value3 = '0.00'
  //     this.loading = false
  //   }, 1000)
  // },
  methods: {
    formatter(value) {
      if (Number.isNaN(+value)) return '0.00'

      const nf = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        roundingMode: 'trunc',
      })

      return nf.format(+value)
    },

    onLessThanMin() {
      this.$swal({ ...SweetAlert.error, text: 'The value should be greater than or equal to 0.00' })
    },

    onGreaterThanMax() {
      this.$swal({ ...SweetAlert.error, text: 'The value should be less than or equal to 10000.55' })
    },
  },
}
</script>

<style lang="scss" scoped>
.gx {
  column-gap: 6px;
}
</style>
