<template>
  <b-form-group
    :label-for="id"
    :label-cols-md="column"
  >
    <template v-if="label" v-slot:label>
      <span style="font-size: 1rem; font-weight: 600;">
        {{ label }} <span v-if="required" class="text-danger">*</span>
      </span>
    </template>
    <b-input-group
      class="input-group-merge"
      :class="state ? 'is-valid' : state === null ? '' : 'is-invalid'"
    >
      <input
        :id="id"
        ref="input"
        v-model="formattedValue"
        :placeholder="placeholder"
        type="text"
        :readonly="readonly"
        :disabled="disabled || loading"
        autocomplete="off"
        :class="['form-control', `${state ? 'is-valid' : state === null ? '' : 'is-invalid'}`]"
        @input="onInput"
        @keydown="onKeydown"
        @blur="setMinMaxValue(formattedValue)"
      >
      <b-input-group-append v-if="!loading && (copiable || isClearIconShow)" is-text>
        <template v-if="copiable">
          <feather-icon
            :id="`copy-${id}`"
            icon="CopyIcon"
            class="cursor-pointer"
            @click="copyValue"
          />
          <b-tooltip
            class="w-auto"
            :target="`copy-${id}`"
            triggers="hover"
          >
            {{ isCopied ? 'Copied' : 'Copy' }}
          </b-tooltip>
        </template>
        <feather-icon
          v-if="isClearIconShow"
          icon="XIcon"
          class="cursor-pointer"
          @click="$emit('input', '0.00')"
        />
      </b-input-group-append>
    </b-input-group>
    <b-form-valid-feedback :state="state">
      {{ validFeedback }}
    </b-form-valid-feedback>
    <b-form-invalid-feedback :state="state">
      {{ invalidFeedback }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: '0.00',
    },
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    column: {
      type: String,
      default: '0',
    },
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    copiable: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: null,
    },
    validFeedback: {
      type: String,
      default: '',
    },
    invalidFeedback: {
      type: String,
      default: '',
    },
    useGrouping: {
      type: Boolean,
      default: true,
    },
    returnWithComma: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    minimumFractionDigits: {
      type: Number,
      default: null,
    },
    maximumFractionDigits: {
      type: Number,
      default: null,
    },
    roundingMode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isInitialized: false,
      formattedValue: '0.00',
      isCopied: false,
    }
  },
  computed: {
    isMinValid() {
      return (this.min !== null && this.max === null && this.min >= 0)
          || (this.min !== null && this.max !== null && this.min >= 0 && this.max >= 0 && this.min <= this.max)
    },

    isMaxValid() {
      return (this.min === null && this.max !== null && this.max >= 0)
          || (this.min !== null && this.max !== null && this.min >= 0 && this.max >= 0 && this.min <= this.max)
    },

    isClearIconShow() {
      return !this.readonly && !this.disabled && !this.required && this.clearable && +this.value !== 0
    },
  },
  watch: {
    value() {
      if (!this.isInitialized) {
        this.setMinMaxValue(this.value)
        this.isInitialized = true
        return
      }
      const unmaskedValue = `${this.value}`.replace(/,/g, '')
      this.formattedValue = unmaskedValue ? this.formatter(+unmaskedValue) : '0.00'
    },

    formattedValue() {
      const unmaskedValue = this.formattedValue.replace(/,/g, '')
      this.$emit('input', this.returnWithComma ? this.formattedValue : unmaskedValue)
    },
  },
  mounted() {
    if (!this.loading && !this.isInitialized) {
      this.setMinMaxValue(this.value)
      this.isInitialized = true
    }
  },
  methods: {
    async copyValue() {
      this.isCopied = true
      await navigator.clipboard.writeText(this.formattedValue)
      setTimeout(() => {
        this.isCopied = false
      }, 500)
    },

    formatter(value, customUseGrouping = null) {
      if (Number.isNaN(+value)) return '0.00'

      const nf = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        useGrouping: customUseGrouping === null ? this.useGrouping : customUseGrouping,
        ...this.minimumFractionDigits && { minimumFractionDigits: this.minimumFractionDigits },
        ...this.maximumFractionDigits && { maximumFractionDigits: this.maximumFractionDigits },
        ...this.roundingMode && { roundingMode: this.roundingMode },
      })

      return nf.format(+value)
    },

    setMinMaxValue(val) {
      const unmaskedValue = `${val}`.replace(/,/g, '')

      if (this.isMinValid && +unmaskedValue < this.min) {
        this.formattedValue = this.formatter(+this.min)
        this.$emit('less-than-min')
        return
      }

      if (this.isMaxValid && +unmaskedValue > this.max) {
        this.formattedValue = this.formatter(+this.max)
        this.$emit('greater-than-max')
        return
      }

      this.formattedValue = unmaskedValue ? this.formatter(+unmaskedValue) : '0.00'
    },

    onKeydown(e) {
      const { value, selectionStart, selectionEnd } = e.target
      const valueDotSplit = value.split('.')

      const isPreventDash = e.key === '-' && (/^-/g.test(e.target.value) || (selectionStart !== 0 && selectionEnd !== 0) || (this.min !== null && this.min >= 0))
      const isPreventDot = e.key === '.'
      const isDeleteContentBackward = e.which === 8
      const isDeleteContentForward = e.which === 46

      if (isPreventDash || isPreventDot) {
        e.preventDefault()
      }

      // Bypass dot
      if (this.minimumFractionDigits && isPreventDot && valueDotSplit[0].length === selectionStart && valueDotSplit[0].length === selectionEnd) {
        this.$refs.input.setSelectionRange(selectionStart + 1, selectionEnd + 1)
      }

      // Bypass comma deleteContentBackward
      if (isDeleteContentBackward) {
        if (value[selectionStart - 1] === ',') {
          this.$refs.input.setSelectionRange(selectionStart - 1, selectionEnd - 1)
        }
      }

      // Bypass comma deleteContentForward
      if (isDeleteContentForward) {
        if (value[selectionStart] === ',') {
          this.$refs.input.setSelectionRange(selectionStart + 1, selectionEnd + 1)
        }
      }
    },

    async onInput(e) {
      const { value: originalValue, selectionStart: prevSelectionStart, selectionEnd: prevSelectionEnd } = e.target
      let newValue = (this.min !== null && this.min >= 0 ? originalValue.replace(/[^0-9.]/g, '') : originalValue.replace(/[^0-9.-]/g, '')).replace(/,/g, '')

      const allowRemoveInputType = (e.inputType === 'deleteContentForward' || e.inputType === 'deleteContentBackward')

      if (this.minimumFractionDigits && allowRemoveInputType && newValue && !/\./g.test(newValue)) {
        newValue = `${newValue.slice(0, newValue.length - 2)}.${newValue.slice(newValue.length - 2)}`
      }

      newValue = this.formatter(+newValue)

      this.formattedValue = newValue

      await this.$nextTick()

      const { selectionEnd } = e.target

      let posDiff = 0
      const formattedValueDotSplit = this.formattedValue.split('.')

      if (originalValue.length === (this.formattedValue.length - 1)) {
        posDiff = 1
      }

      if (originalValue.length === (this.formattedValue.length + 1)) {
        posDiff = -1
      }

      if (e.inputType === 'insertFromPaste') {
        const commaCount = this.formattedValue.match(/,/g)?.length

        if (commaCount) {
          posDiff = commaCount
        }
      }

      if (this.minimumFractionDigits && selectionEnd !== prevSelectionStart) {
        this.$refs.input.setSelectionRange(prevSelectionStart + posDiff, prevSelectionEnd + posDiff)
      }

      if (!this.minimumFractionDigits) {
        if (prevSelectionStart === 0 && selectionEnd !== 1) {
          this.$refs.input.setSelectionRange(prevSelectionStart, prevSelectionEnd)
        } else {
          this.$refs.input.setSelectionRange(prevSelectionStart + posDiff, prevSelectionEnd + posDiff)
        }
      }

      if (e.inputType !== 'insertFromPaste' && this.$refs.input.selectionStart > formattedValueDotSplit[0].length) {
        this.$refs.input.setSelectionRange(prevSelectionStart, prevSelectionEnd)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.form-control {
  text-align: right;
  &[readonly] {
    background-color: white;
  }
  &.is-invalid, &.is-valid {
    background-position: left calc(0.3625em + 0.219rem) center;
  }
}

.input-group-append {
  .input-group-text {
    column-gap: 6px;
  }
}
</style>
