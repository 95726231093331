<template>
  <div class="p-2">
    <HeroInputMoneyV2Demo />
    <hr>
  </div>
</template>

<script>
import HeroInputMoneyV2Demo from '@/views/components/demo/HeroInputMoneyV2Demo.vue'

export default {
  components: {
    HeroInputMoneyV2Demo,
  },
}
</script>
